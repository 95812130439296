import React from 'react'
import Layout from '../layouts'
import './index.css'
import bgImage from '../images/pexels-thirdman-5327649.jpg'
import DRSlogo from '../images/CertifiedDRSAgent-removebg-preview.png'
import logo1 from '../images/realtor.png'
import logo2 from '../images/buffini.png'
import logo3 from '../images/drs.png'
import logo4 from '../images/dignity-health.png'
import BGWhite from '../images/bg-white-1.jpg'
import { Link } from 'gatsby'
import CertifiedAgent from '../components/certifiedAgent'

const PhysicanMedical = () => (
  <Layout head={{ title: 'Physicians & Medical Professionals' }}>
    <div className="page-header-bg">
      <div className="container">
        <div className="header-text">
          <p className="lead text-uppercase">Welcome Home</p>
          <h1 className="text-white text-uppercase">
            Physician Relocation is our specialty
          </h1>
        </div>
      </div>
      <div
        className="header-bg"
        style={{
          backgroundImage: `url(${bgImage})`,
          zIndex: -1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '70%',
        }}
      />
    </div>

    <div>
      <div className="component bg-dirty-white">
        <div className="container">
          <div className="row justify-content-center mx-1">
            <div className="col-md-10">
              {/* <div className="embed-container">
                <iframe
                  src="https://www.youtube.com/embed/WEeSa7NvBO4"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div> */}
              <div className="mb-5 px-lg-4 text-center">
                <p>
                  We help physicians and medical professionals relocate to the
                  Colorado Springs area! We specialize in providing concierge
                  service to our clients with respect to your time and unique
                  schedules.
                </p>
                <p>
                  We can provide video tours of homes should your schedule
                  and/or distance require. We listen to your needs and negotiate
                  the best deals in your interest! Whether you are relocating to
                  Colorado Springs or are ready to buy or sell in our area, we
                  strive to go above and beyond to meet your needs and make
                  homeownership easier for you!
                </p>
                {/* <p className="font-weight-bold">
                  Watch the video above for a quick overview of The Brian Boals
                  Team and we support you in our thriving community.
                </p> */}
              </div>
              <div className="text-center">
                <Link to="/contact" className="alink hvr-shadow">
                  Connect with Our Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CertifiedAgent />
      <div
        className="component"
        style={{
          background: `url(${BGWhite}) top center no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="row justify-content-around align-items-center">
                <div className="col-md-5 text-center">
                  <img src={DRSlogo} className="img-fluid" alt="DRS Logo" />
                </div>
                <div className="col-md-6 text-left">
                  <h2 className="mb-5 text-uppercase">
                    Benefits of working with a DRS Agent
                  </h2>
                  <ul className="styled-check-list">
                    <li>
                      The quickest, easiest way to find a trusted professional
                      real estate agent with experience with physicians and
                      medical professionals.
                    </li>
                    <li>Work with an agent endorsed by AMA, AMSA, and SOMA.</li>
                    <li>
                      Saves you time and money by efficiently matching you with
                      an agent.
                    </li>
                    <li>
                      Only vetted, experienced agents are invited to join the
                      DRS Network.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 bg-dirty-white">
        <div className="container">
          <div className="d-flex justify-content-center flex-wrap">
            {logos.map((logo, i) => (
              <div className="p-5" key={i}>
                <img
                  src={logo.image}
                  alt={logo.alt}
                  style={{ width: '150px', height: 'auto' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default PhysicanMedical

const logos = [
  {
    image: logo1,
    alt: 'Realtor',
  },
  {
    image: logo2,
    alt: 'Buffini',
  },
  {
    image: logo3,
    alt: 'DRS',
  },
  // {
  //   image: logo4,
  //   alt: 'Dignity Health',
  // },
]
